import { leagueFetch } from '@leagueplatform/league-fetch';
import { captureAppMessage } from '@leagueplatform/observability';
import { SENTRY_CONSTANTS, TENANT_ID } from 'common/constants/constants';
import { API_PATHS } from 'common/constants/api';

/**
 * Logs an error message for API failures.
 * @param message The error message to log.
 * @param statusCode The HTTP status code associated with the error.
 */
function logApiError(message: string, statusCode: number): void {
  captureAppMessage(`Partner API Error - ${statusCode}`, {
    context: {
      contextDetails: {
        info: message,
      },
    },
    ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    severityLevel: 'error',
  });
}

/**
 * Extracts the partner idToken from the API response.
 * @param response The response object from the partner identity API.
 * @returns The idToken if it exists, or an empty string.
 */
function extractPartnerIdToken(response: any): string {
  return response?.data?.attributes?.idToken ?? '';
}

/**
 * Logs an exception message for unexpected errors.
 * @param message The error message to log.
 * @param error The exception object to log.
 */
function logException(message: string, error: any): void {
  captureAppMessage(`${message} - ${error.name}`, {
    context: {
      contextDetails: {
        info: `Error: ${error.message}`,
      },
    },
    ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    severityLevel: 'error',
  });
}

/**
 * Fetches the partner idToken for federated logout.
 * @returns The idToken provided by the backend, or an empty string if an error occurs.
 */
export async function getIdentityPartnerIdToken(): Promise<string> {
  try {
    const url = `${API_PATHS.IDENTITY_PARTNER_TOKEN}?partnerId=${TENANT_ID}`;
    const response = await leagueFetch(url);

    if (!response.ok) {
      logApiError(
        `Failed to fetch partner idToken. HTTP Status: ${response.status}`,
        response.status,
      );
      return '';
    }

    const data = await response.json();
    return extractPartnerIdToken(data);
  } catch (error: any) {
    logException(`Exception occurred while fetching partner idToken.`, error);
    return '';
  }
}
