import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { PageHeader } from 'components/page-header/page-header.component';
import { Box, ParagraphText, StackLayout } from '@leagueplatform/genesis-core';
import { CONTENT_MAX_WIDTH } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { Logo } from 'components/logo.component';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import { SCREEN_NAMES } from 'common/constants/analytics';
import { APP_NAME, APP_NAME_LONG } from 'common/constants/constants';

export const AboutPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(
    formatMessage({ id: 'STR_ABOUT_SCAN_HEALTH' }, { appName: APP_NAME_LONG }),
  );
  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.ABOUT_SCAN_HEALTH,
  });

  const isBelowTablet = useIsBelowTablet();

  return (
    <>
      <PageHeader
        text={formatMessage(
          { id: 'STR_ABOUT_SCAN_HEALTH' },
          { appName: APP_NAME_LONG },
        )}
      />
      <Box css={{ paddingInline: '$two' }}>
        <Box
          css={{
            maxWidth: CONTENT_MAX_WIDTH,
            marginInline: 'auto',
            paddingBlockStart: '$two',
            paddingBlockEnd: '$five',
          }}
        >
          <StackLayout
            orientation={isBelowTablet ? 'vertical' : 'horizontal'}
            verticalAlignment="center"
            horizontalAlignment="center"
            css={{
              maxWidth: CONTENT_MAX_WIDTH,
              boxShadow: '$card',
              backgroundColor: '$surfaceCardSecondary',
              paddingBlock: '$two',
              paddingInline: isBelowTablet ? '$oneAndHalf' : '$two',
              marginBlock: 'auto',
              gap: '$three',
            }}
          >
            <Box
              css={{
                flexShrink: 0,
                backgroundColor: '$decorativeBrandSecondaryDefault',
                borderRadius: '$extraLarge',
                height: 120,
                width: 120,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Logo width={90} />
            </Box>
            <ParagraphText>
              {formatMessage(
                { id: 'STR_ABOUT_SCAN_HEALTH_CONTENT' },
                { appName: APP_NAME },
              )}
            </ParagraphText>
          </StackLayout>
        </Box>
      </Box>
    </>
  );
};
