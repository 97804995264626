import {
  HeadingText,
  ParagraphText,
  TextAction,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { type MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import type { PriorAuthorizationStatuses } from 'common/types/types';
import { AuthorizationStatusBadge } from 'components/prior-authorization-status/authorization-status-badge.component';
import { Link } from 'react-router-dom';
import { useElevatedCard } from 'hooks/use-elevated-card/use-elevated-card';

type AuthorizationCardNodeProperties = {
  heading: string;
  body: string;
  status: PriorAuthorizationStatuses;
};

type AuthorizationCardNodeActions = {
  onClick: MasonryDeeplinkAction;
};

type AuthorizationCardNode = MasonryEngineNode<
  'authorizationCard',
  AuthorizationCardNodeProperties,
  AuthorizationCardNodeActions
>;

type AuthorizationCardProps =
  MasonryEngineNodeRendererProps<AuthorizationCardNode>;

export const AuthorizationCard = ({
  heading,
  body,
  status,
  onClick,
}: AuthorizationCardProps) => {
  const { ElevatedCard, primaryActionRef } = useElevatedCard();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ElevatedCard>
      <HeadingText
        level="3"
        size="sm"
        css={{
          typography: '$subtitleOne',
          marginBlockEnd: '$none',
        }}
      >
        <TextAction
          as={Link}
          to={onClick?.payload.url}
          ref={primaryActionRef}
          css={{
            display: 'block',
            width: 'fit-content',
          }}
          onClick={(event: React.MouseEvent) => {
            if (onClick) {
              invokeAction({
                event,
                ...onClick,
              });
            }
          }}
        >
          {heading}
        </TextAction>
      </HeadingText>
      <ParagraphText
        css={{
          typography: '$bodyTwo',
          marginBlockEnd: '$none',
        }}
      >
        {body}
      </ParagraphText>
      <AuthorizationStatusBadge status={status} />
    </ElevatedCard>
  );
};
