// Masonry v1 or v1.5 containers
export const MASONRY_WS_CONTAINERS = {
  HOME_PAGE: 'scan_homepage',
  CARE_HOME_PAGE: 'scan_care',
};

// MASONRY 2.0 containers
export const MASONRY_ROOT_NODES = {
  AUTHORIZATIONS: 'authorizations$rootWeb',
  SECTION_ITEMS: 'authorizations$rootWeb-section-items',
  LEARN_MORE: 'authorizationLearnMore$root',
  LEARN_MORE_WEB: 'authorizationLearnMore$webRoot',
  AUTHORIZATION_DETAILS: 'authorizationDetails$root',
  PRESCRIPTIONS: 'prescriptions$rootWeb',
  PRESCRIPTION_DETAILS: 'prescriptionDetails$root',
  REFILL_SCORE: 'yourRefillScoreSummary$root',
  REFILL_SCORE_DETAILS: 'yourRefillScoreDetails$root',
  REFILL_SCORE_LEARN_MORE: 'yourRefillScoreDefinitions$root',
  REFILL_SCORE_LEARN_MORE_WEB: 'yourRefillScoreDefinitions$rootWeb',
  REFILL_SCORE_DEFINITIONS: 'yourRefillScoreDefinitions$root',
  CONTACT_SCAN: 'contactScan$root',
};

export const MASONRY_NAMESPACES = {
  DEFAULT: 'default',
  PRIOR_AUTHORIZATIONS_STATUS: 'scan_authorizations',
  PRIOR_AUTHORIZATION_STATUS_DETAILS: 'scan_authorization_details',
  PRESCRIPTIONS: 'scan_prescriptions',
  PRESCRIPTION_DETAILS: 'scan_prescription_details',
  REFILL_SCORE: 'scan_refill_score',
  REFILL_SCORE_DETAILS: 'scan_refill_score_details',
  CONTACT_SCAN: 'scan_contact_scan',
  HEALTH_JOURNEY: 'health_journey',
};

export const MASONRY_WIDGET_TYPES = {
  HERO_BANNER: 'heroBanner',
  AUTHORIZATION: {
    CARD: 'authorizationCard',
    LEARN_MORE: {
      CARD: 'authorizationLearnMoreCard',
      HEADER: 'authorizationLearnMoreHeader',
      DEFINITION: 'authorizationStatusLearnMoreDefinition',
      STACK: 'authorizationLearnMoreStack',
    },
    EMPTY_STATE: 'authorizationsEmptyState',
    DETAILS_HEADER: 'authorizationDetailsHeader',
    SERVICE: {
      CARD: 'authorizationServiceCard',
      INFO_SECTION: 'authorizationServiceInfoSection',
    },
  },
  RICH_TEXT: 'richtext',
  LOAD_MORE_BUTTON: 'loadMoreButton',
  PAGINATION_LAYOUT: 'paginationLayout',
  BUTTON: 'button',
  CONTAINER_SPINNER: 'containerSpinner',
  SMALL_CARD: 'smallCard',
  SECTION_HEADER: 'sectionHeader',
  KEY_VALUE_HEADING_DESCRIPTION: 'keyValueHeadingDescription',
  GRID_LAYOUT: 'gridLayout',
  SIMPLE_BADGE_CARD: 'simpleBadgeCard',
  LEARN_MORE_DEFINITION: 'learnMoreDefinition',
  LEARN_MORE_HEADER: 'learnMoreHeader',
  DESCRIPTIVE_SECTION_WITH_IMAGE: 'descriptiveSectionWithImage',
  HEADING_WITH_ICON: 'headingWithIcon',
  BADGE_IMAGE_SUMMARY: 'badgeImageSummary',
  EXTRA_SMALL_CARD: 'extraSmallCard',
  PAGE_HEADER: 'pageHeader',
  ACTIVITY_STACK: 'activity_stack',
};

export const MASONRY_ACTION_TYPES = {
  DEEPLINK: 'deeplink',
  SET_PAGE: 'setPage',
};
