import { useEffect, useRef } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { AppNavBarComponent } from 'components/header-nav/nav.component';
import { GlobalFooter } from 'components/global-footer/global-footer.component';
import { ROUTE_PATHS } from 'common/constants/constants';
import { SDK_CSS_OVERRIDES } from 'common/constants/ui';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  showNavBar?: boolean;
  showFooter?: boolean;
}>;

export const useScrollTopOnLocationChange = () => {
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return containerRef;
};

export const BasePage: React.FC<BasePageProps> = ({
  children,
  showNavBar = true,
  showFooter = false,
  css,
}: BasePageProps) => {
  const containerRef = useScrollTopOnLocationChange();

  useScrollTopOnLocationChange();

  const isLandingPage = useRouteMatch({
    path: [
      ROUTE_PATHS.HOME,
      getModulePath(LEAGUE_MODULE_NAMES.aboutMe),
      getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
      getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
      getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
      getModulePath(LEAGUE_MODULE_NAMES.wallet),
    ],
    exact: true,
  });

  return (
    <Box
      ref={containerRef}
      css={{
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: showNavBar ? 'auto 1fr auto' : '1fr',
        overflowInline: 'hidden',
        overflowX: 'hidden',
      }}
    >
      {showNavBar && <AppNavBarComponent />}
      <Box
        as="main"
        id="main-content"
        css={{
          position: 'relative',
          backgroundColor: '$surfaceBackgroundPrimary',
          zIndex: '0',
          ...css,
        }}
      >
        <Box className="wrapper" css={SDK_CSS_OVERRIDES}>
          {children}
        </Box>
      </Box>
      {((showNavBar && isLandingPage) || showFooter) && <GlobalFooter />}
    </Box>
  );
};
