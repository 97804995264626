import { leagueFetch } from '@leagueplatform/league-fetch';
import { useQuery } from 'react-query';
import { API_PATHS, QUERY_KEYS } from 'common/constants/api';
import { components } from 'api/gen/communication-preferences';
import { type CommunicationPreferencesSubmitVariables } from 'hooks/use-submit-communication-preferences/use-submit-communication-preferences.hook';

// Uses the section and preference name to create a unique identifier for the form's fields.
// Subject to change when staging API's are available.
export const normalizeFieldName = (
  sectionName: string,
  preferenceName: string,
): string => {
  const formattedName = (name: string) =>
    name.toLowerCase().replaceAll(' ', '-');
  return `${formattedName(sectionName)}_${formattedName(preferenceName)}`;
};

export const getFormDefaultValues = (
  communicationPreferencesData: components['schemas']['getCommunicationPreferencesSchema']['data'],
): CommunicationPreferencesSubmitVariables => {
  let preferredMethods = {};

  if (!communicationPreferencesData) {
    return preferredMethods;
  }

  communicationPreferencesData.forEach((section) => {
    const { preferences, sectionName } = section.attributes || {};

    if (!preferences || !sectionName) {
      return;
    }

    preferences.forEach((preference) => {
      const { name, preferredMethod } = preference.attributes || {};

      if (!name || !preferredMethod?.communicationMethodCode) {
        return;
      }

      const formattedName = normalizeFieldName(sectionName, name);
      const method = preferredMethod.communicationMethodCode;

      preferredMethods = {
        ...preferredMethods,
        [formattedName]: method,
      };
    });
  });

  return preferredMethods;
};

export const useGetCommunicationPreferences = () =>
  useQuery({
    queryKey: [QUERY_KEYS.COMMUNICATION_PREFERENCES],
    queryFn: async () => {
      const response = await leagueFetch(API_PATHS.COMMUNICATION_PREFERENCES);

      if (!response?.ok) {
        throw new Error(
          `Error Response Code ${response?.status}: ${await response.text()}`,
        );
      }

      return (await response.json()) as Promise<
        components['schemas']['getCommunicationPreferencesSchema']
      >;
    },
    select: (data) => data?.data,
    suspense: true,
  });
