import { ADDRESS_TYPES } from 'common/constants/account-info';
import type { Location, Locations } from 'common/types/account-info-form-types';

export const composeDisplayAddress = (
  address: Location,
  options?: { noUnit: boolean },
) => {
  const { address1, address2, city, state, postal } = address || {};
  const addressFront = options?.noUnit ? [address1] : [address1, address2];
  return (
    addressFront
      .concat([city, state, postal])
      .filter((element) => element)
      .join(', ') || ''
  );
};

// Determine new vs old address, checking for existence of effectiveDate.
// Determine mailing vs residential, checking for type.
export const getAddresses = (locations: Locations) => {
  const result: any = {};
  if (locations) {
    locations.forEach((location) => {
      if (location.type === ADDRESS_TYPES.PERMANENT) {
        // effectiveDate doesn't exist on league-web's user profile
        // @ts-ignore
        if (location.effectiveDate) {
          result.newResidentialAddress = location;
        } else {
          result.oldResidentialAddress = location;
        }
      }
      if (location.type === ADDRESS_TYPES.MAILING) {
        // @ts-ignore
        if (location.effectiveDate) {
          result.newMailingAddress = location;
        } else {
          result.oldMailingAddress = location;
        }
      }
    });
  }
  return result;
};
