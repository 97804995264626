import {
  LEAGUE_MODULE_NAMES,
  Environment,
  type LeagueConfig,
} from '@leagueplatform/core';
import { logout } from 'common/utils/logout';
import { TENANT_ID, APP_ID, ROUTE_PATHS } from 'common/constants/constants';
import { MASONRY_WS_CONTAINERS } from 'common/constants/masonry';
import { scanHealthTheme } from 'theme/scan-theme';
import FeatureHighlightBackground from 'assets/feature-highlights-background.png';
import JourneyHeaderBackground from 'assets/journey-header-background.svg';
import Trophy from 'assets/trophy.svg';
import NoDocumentsAvailable from 'assets/no-documents-available.png';
import EN_STRING_OVERRIDES from 'locales/en.json';
import ES_STRING_OVERRIDES from 'locales/es.json';
import KO_STRING_OVERRIDES from 'locales/ko.json';
import ZH_STRING_OVERRIDES from 'locales/zh.json';
import VI_STRING_OVERRIDES from 'locales/vi.json';
import { ToastMessage } from 'components/toast-message/toast-message.component';

// Custom interface for League Config that does not include `auth` on the core config
export interface LeagueConfigWithoutAuth extends Omit<LeagueConfig, 'core'> {
  core: Omit<LeagueConfig['core'], 'auth'>;
}

// Double-check apps/scan/src/common/constants/constants.ts
// for hardcoded paths before overriding any routes here.
export const routesConfig = {
  [LEAGUE_MODULE_NAMES.wallet]: ROUTE_PATHS.BENEFITS,
};

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_CONNECTION,
  VITE_APP_ENV,
} = import.meta.env;

export const webAuthConfig = {
  clientOptions: {
    domain: VITE_AUTH0_DOMAIN,
    client_id: VITE_AUTH0_CLIENT_ID,
    redirect_uri: window.location.origin,
    connection: VITE_AUTH0_CONNECTION,
  },
  idleSessionLifetimeHours: 0.23, // Slightly shorter than the BE session timeout
  sessionTimeoutOptions: {
    onSessionExpire: () => logout(),
  },
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL,
  url: VITE_API_URL,
  legacyRestApi: VITE_LEGACY_REST_API_URL,
};

const appConfig = {
  contentUrl: VITE_CONTENT_URL,
  appEnvironment: VITE_APP_ENV as Environment,
};

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const configWithoutAuth: LeagueConfigWithoutAuth = {
  core: {
    tenantId: TENANT_ID,
    clientId: APP_ID,
    analytics: analyticsConfig,
    api: apiConfig,
    ...appConfig,
    customMethods: customMethodsConfig,
    routing: { isUsingLeagueRouting: true },
    ui: {
      theme: scanHealthTheme,
      components: {
        pageHeader: { shape: 'line' },
        toastMessage: { component: ToastMessage },
      },
    },
    i18n: {
      strings: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
        es: ES_STRING_OVERRIDES,
        zh: ZH_STRING_OVERRIDES,
        vi: VI_STRING_OVERRIDES,
        ko: KO_STRING_OVERRIDES,
      },
    },
  },
  assets: {
    overrides: {
      FEATURE_HIGHLIGHT_BACKGROUND: FeatureHighlightBackground,
      ERROR_STATE_ICON: NoDocumentsAvailable,
      EMPTY_STATE_HISTORY: Trophy,
    },
  },
  careDiscovery: {
    masonryAppId: {
      left: MASONRY_WS_CONTAINERS.CARE_HOME_PAGE,
    },
    masonryAppVersion: '1.5',
  },
  healthJourney: {
    headerConfig: {
      backgroundImageSrc: JourneyHeaderBackground,
    },
  },
  __internal: {
    shouldApplyCSSReset: true,
    shouldApplyLegacyGlobalStyles: true,
    shouldIgnoreDOMLangAttributeForLocale: true,
  },
  wallet: {
    showLandingPageHeaderBranding: true,
  },
};
