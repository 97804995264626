import { type FunctionComponent, useMemo } from 'react';
import {
  type ToastMessageProps,
  TOAST_STATUS,
} from '@leagueplatform/toast-messages';
import { FormattedHTMLMessage } from '@leagueplatform/locales';
import {
  type GDSStatus,
  StackItem,
  StackLayout,
  StatusBanner,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import type { LocalesKey } from 'common/types/types';

const StatusBannerMap: Record<TOAST_STATUS, GDSStatus> = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  note: 'neutral',
  attention: 'warning',
  info: 'info',
  inverted: 'brand',
};

export const ToastMessage: FunctionComponent<ToastMessageProps> = ({
  type,
  text,
  dismiss,
  messageId,
  textId,
  content,
  contentId,
  values,
}) => {
  const statusBannerTitle = useMemo(() => {
    if (text) return text;

    if (textId)
      return (
        <HeadingText level="display" size="sm">
          <FormattedHTMLMessage id={textId as LocalesKey} values={values} />
        </HeadingText>
      );

    return null;
  }, [text, textId, values]);

  const statusBannerContent = useMemo(() => {
    if (content) return content;

    if (contentId)
      return (
        <ParagraphText
          css={{ typography: '$bodyTwo', marginBlockStart: '$quarter' }}
        >
          <FormattedHTMLMessage id={contentId as LocalesKey} values={values} />
        </ParagraphText>
      );

    return null;
  }, [content, contentId, values]);

  return (
    <StackLayout orientation="horizontal" horizontalAlignment="end">
      <StackItem
        css={{
          width: 392,
          position: 'relative',
          pointerEvents: 'auto',
          cursor: 'pointer',
        }}
      >
        <StatusBanner
          status={StatusBannerMap[type]}
          title={statusBannerTitle}
          onDismissClick={() => dismiss(messageId)}
          aria-live="polite"
          css={{
            borderRadius: '$extraLarge',
            '.GDS-status-banner-dismiss-button': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: '$secondaryBackgroundHovered',
              },
            },
          }}
        >
          {statusBannerContent}
        </StatusBanner>
      </StackItem>
    </StackLayout>
  );
};
