export const PHONE_NUMBER_TYPES = {
  MOBILE: 'mobile',
  HOME: 'home',
  ALTERNATE: 'alternate',
};

export const ADDRESS_TYPES = {
  PERMANENT: 'permanent', // BE name
  RESIDENTIAL: 'residential', // FE name
  MAILING: 'mailing',
};

export const ADDRESS_FORM_ELEMENT_IDS = {
  DISPLAY_ADDRESS: 'displayAddress',
  STREET_ADDRESS: 'streetAddress',
  STREET_ADDRESS_2: 'streetAddress2',
  CITY: 'city',
  ZIP_CODE: 'zipCode',
  STATE: 'state',
  COUNTY: 'county',
  EFFECTIVE_DATE: 'effectiveDate',
};
