import type { GDSStyleObject } from '@leagueplatform/genesis-core';

export const CTA_FOOTER_HEIGHT = '98px';
export const ACCOUNT_SETUP_CONTENT_MAX_WIDTH = '708px';
export const CONTENT_MAX_WIDTH = 952;
export const BACK_BUTTON_WIDTH = 125;
export const PAGE_NAVIGATION_WIDTH = 1178;
export const PAGE_NAVIGATION_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid
export const HERO_BANNER_HEIGHT = 184;
export const ELEMENT_IDS = {
  PAGE_HEADER: 'page-header',
};

export const FORM_LEGEND_STYLE = {
  '.GDS-fieldset-legend': {
    typography: '$headingFour',
    marginBlockEnd: '$one',
    color: '$onSurfaceTextPrimary',
  },
};

export const SDK_CSS_OVERRIDES: GDSStyleObject = {
  // Override activity card styles in the entire app
  // https://github.com/LeaguePlatform/scan-web/pull/255
  '.GDS-elevated-card': {
    boxShadow: '$dropdown',
    border: 'none',
    borderColor: 'transparent !important',
    '.GDS-card-click-area': {
      border: 'none',
      position: 'relative',
      '&:hover': {
        '&:after': {
          content: '',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          border: '$borderWidths$card solid $colors$interactiveFocusOuter',
          borderRadius: '$medium',
        },
      },
    },
  },
  // Override activity toolbox styles
  // https://github.com/LeaguePlatform/scan-web/pull/259
  '.health-activity-layout': {
    backgroundColor: '$surfaceBackgroundPrimary',
  },
  '.health-activity-content': {
    backgroundColor: '$surfaceCardSecondary',
    boxShadow: '$dropdown',
  },
  '.toolbox-richtext': {
    backgroundColor: 'transparent !important',
  },
  '.toolbox-option-wrapper': {
    border:
      '$borderWidths$card solid $colors$onSurfaceBorderSubdued !important',
  },
  '.toolbox-badge': {
    backgroundColor: '$surfaceBackgroundSecondary',
    borderRadius: '$medium',
    '& > span': {
      typography: '$overline',
    },
  },
  // Override avatar style in Messaging SDK
  // https://github.com/LeaguePlatform/scan-web/pull/288
  '.avatar-wrapper': {
    backgroundColor: '$decorativeBrandSecondaryBright',
  },
  // Override styles in Messaging subject selection screens
  // https://github.com/LeaguePlatform/scan-web/pull/292
  '.new-message-page-wrapper': {
    backgroundColor: '$surfaceBackgroundPrimary',
    '& > div': {
      overflowY: 'auto',
    },
  },
  '.new-message-page-questionnaire-wrapper': {
    backgroundColor: '$surfaceCardSecondary',
    boxShadow: '$card',
    height: 'fit-content',
    '& > h1': {
      typography: '$headingTwo',
      color: '$onSurfaceTextPrimary',
    },
  },
  '.new-message-page-questionnaire-wrapper button': {
    display: 'none',
  },
  '.new-message-page-questionnaire-wrapper h2': {
    typography: '$headingOne',
  },
  '.new-message-page-footer': {
    backgroundColor: '$surfaceCardSecondary',
    boxShadow: '$card',
    position: 'relative',
  },
  // Override Messaging message input field styles
  // https://github.com/LeaguePlatform/scan-web/pull/304
  '.thread-preview-row.selected': {
    backgroundColor: '$highlightBackgroundSubdued',
  },
  '.auto-resize-textarea': {
    backgroundColor: '$surfaceBackgroundPrimary',
    borderColor: '$onSurfaceBorderDefault',
    '&:hover': {
      borderColor: '$onSurfaceBorderDefault',
    },
  },
  // Prevent More Options footer in About Me from covering app footer
  // https://github.com/LeaguePlatform/scan-web/pull/326
  '.about-me-sidebar': {
    '@mobile': { position: 'absolute' },
    '@mobileLandscape': { position: 'absolute' },
    '@tablet': { position: 'absolute' },
  },
  '.health-profile-page-children-wrapper': {
    marginBottom: '$two',
    '@mobile': { marginBottom: '180px' }, // The SDK uses margin-bottom, so this is what we need to override
    '@mobileLandscape': { marginBottom: '180px' },
    '@tablet': { marginBottom: '180px' },
    // Reduce spacing between Focus Area cards to avoid buttons appearing when there are 3 cards
    '& .GDS-tab-panel ul.genesis > li:has(.GDS-stack-item)': {
      marginInlineEnd: 20,
      '&:last-child': {
        marginInlineEnd: '$none',
      },
    },
  },
  // Prevent activity buttons from hiding under the top bar
  // https://github.com/LeaguePlatform/scan-web/pull/326
  '.health-activity-remove, .health-activity-close': {
    top: '$five',
  },
};
