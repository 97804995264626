import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  HeadingText,
  UtilityText,
  Popover,
  ParagraphText,
  StackLayout,
  ChoiceButton,
  Divider,
  Button,
} from '@leagueplatform/genesis-core';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import {
  usePageViewAnalytics,
  SCREEN_NAMES,
  PRODUCT_AREA,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  useGetCommunicationPreferences,
  normalizeFieldName,
  getFormDefaultValues,
} from 'hooks/use-get-communication-preferences/use-get-communication-preferences.hook';
import { type CommunicationPreferencesSubmitVariables } from 'hooks/use-submit-communication-preferences/use-submit-communication-preferences.hook';
import { PageHeader } from 'components/page-header/page-header.component';
import { ActionsList } from 'components/actions-list/actions-list.component';
import { FullPageContentSection } from 'components/content-section/content-section.component';
import { CTAFooter } from 'components/cta-footer/cta-footer.component';
import { ExitWarning } from 'components/modals/exit-warning/exit-warning.component';
import {
  CONTENT_MAX_WIDTH,
  PAGE_NAVIGATION_HEIGHT,
  CTA_FOOTER_HEIGHT,
} from 'common/constants/ui';
import { ROUTE_PATHS } from 'common/constants/constants';
import {
  translateInBottom,
  translateOutBottom,
} from 'common/keyframes/show-and-hide.keyframe';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handle/use-dirty-form-handle.hook';
import { useSubmitCommunicationPreferences } from 'hooks/use-submit-communication-preferences/use-submit-communication-preferences.hook';

type ChoiceButtonClickParams = {
  preferenceName: string;
  communicationMethodCode: string;
};

export const DocumentPreferencesPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const isBelowTablet = useIsBelowTablet();

  const PAGE_HEADING = useMemo(
    () => formatMessage({ id: 'STR_DOCUMENT_PREFERENCES' }),
    [formatMessage],
  );

  // Track dirty forms (with unsaved changes) for ExitWarning
  const { addDirtyForm, removeDirtyForm } = useDirtyFormHandler(PAGE_HEADING);

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.DOCUMENT_PREFERENCES,
  });

  const { data: communicationPreferencesData } =
    useGetCommunicationPreferences();
  const { register, formState, handleSubmit } =
    useForm<CommunicationPreferencesSubmitVariables>({
      defaultValues: getFormDefaultValues(communicationPreferencesData),
    });

  const { submitCommunicationPreferences, isLoading: mutationIsLoading } =
    useSubmitCommunicationPreferences();
  // used to prevent the hide CTAFooter animation from being displayed on initial render
  const [userHasTouchedField, setUserHasTouchedField] =
    useState<boolean>(false);

  useEffect(() => {
    if (formState.isDirty) {
      addDirtyForm();
      if (!userHasTouchedField) {
        // Prevents the translateOutBottom animation from being triggered on inital load.
        setUserHasTouchedField(true);
      }
    } else {
      removeDirtyForm();
    }
  }, [formState.isDirty, userHasTouchedField, addDirtyForm, removeDirtyForm]);

  const choiceButtonLabels = useMemo(
    () => ({
      email: formatMessage({ id: 'STR_EMAIL' }),
      phone: formatMessage({ id: 'STR_PHONE' }),
      mail: formatMessage({ id: 'STR_MAIL' }),
      online: formatMessage({ id: 'STR_ONLINE' }),
      text: formatMessage({ id: 'STR_TEXT' }),
      none: formatMessage({ id: 'STR_NONE' }),
    }),
    [formatMessage],
  );

  const submitDocumentPreferencesForm: SubmitHandler<
    CommunicationPreferencesSubmitVariables
  > = async (values) => {
    submitCommunicationPreferences(communicationPreferencesData, values);
  };

  const handleChoiceButtonClick = useCallback(
    ({ preferenceName, communicationMethodCode }: ChoiceButtonClickParams) => {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.SETTINGS,
        screen_name: SCREEN_NAMES.DOCUMENT_PREFERENCES,
        detail: preferenceName,
        delivery_method: communicationMethodCode,
      });
    },
    [],
  );

  return (
    <>
      <ExitWarning />
      <Box
        css={{
          display: 'grid',
          minHeight: `calc(100vh - ${PAGE_NAVIGATION_HEIGHT}px)`,
          gridGap: isBelowTablet ? undefined : '$three',
          gridTemplateRows: 'auto 1fr',
          paddingBlockEnd: (() => {
            if (formState.isDirty) {
              return isBelowTablet
                ? CTA_FOOTER_HEIGHT
                : `calc(${CTA_FOOTER_HEIGHT} + $three)`;
            }
            return '$three';
          })(),
        }}
      >
        <PageHeader
          text={PAGE_HEADING}
          onBackClick={() =>
            history.push(ROUTE_PATHS.COMMUNICATION_PREFERENCES)
          }
        />
        <Box
          as="form"
          onSubmit={handleSubmit(submitDocumentPreferencesForm)}
          css={{
            paddingInline: '$two',
            '@mobile': {
              paddingInline: '$none',
            },
            '@mobileLandscape': {
              paddingInline: '$none',
            },
          }}
        >
          <FullPageContentSection
            horizontalAlignment="stretch"
            css={{
              maxWidth: CONTENT_MAX_WIDTH,
              minHeight: isBelowTablet ? '100%' : 'auto',
              height: 'auto',
              flex: 0,
            }}
            spacing="$half"
          >
            {communicationPreferencesData?.map((section) => {
              const { attributes: sectionAttributes } = section;
              const { sectionName, preferences } = sectionAttributes;

              if (!sectionName) return null;

              return (
                <ActionsList key={sectionName}>
                  <HeadingText
                    level="2"
                    size="lg"
                    css={{ marginBlockEnd: '$oneAndHalf' }}
                  >
                    {sectionName}
                  </HeadingText>
                  {preferences?.map((preference, index) => {
                    const { attributes: preferenceAttributes } = preference;
                    const {
                      availableMethods,
                      description: preferenceDescription,
                      name: preferenceName,
                    } = preferenceAttributes;

                    if (!preferenceName) return null;

                    const isLastPreference = preferences.length - 1 === index;

                    return (
                      <Box key={preferenceName}>
                        <StackLayout
                          orientation={
                            isBelowTablet ? 'vertical' : 'horizontal'
                          }
                          verticalAlignment="center"
                          horizontalAlignment="stretch"
                        >
                          <StackLayout
                            orientation="horizontal"
                            verticalAlignment="center"
                          >
                            <UtilityText
                              emphasis="emphasized"
                              as="legend"
                              size="sm"
                            >
                              {preferenceName}
                            </UtilityText>
                            <Popover.Root>
                              <Popover.Trigger
                                label={formatMessage(
                                  { id: 'STR_GET_MORE_INFO_ABOUT' },
                                  { preferenceName },
                                )}
                                icon="tinyHelper"
                                priority="secondary"
                                quiet
                                hideLabel
                                css={{ marginInlineStart: '$quarter' }}
                              />
                              <Popover.Content
                                side={isBelowTablet ? 'bottom' : 'right'}
                                align="center"
                                css={{
                                  maxWidth: '367px',
                                  backgroundColor: '$surfaceCardPrimary',
                                }}
                                headingBar={
                                  <StackLayout
                                    horizontalAlignment="end"
                                    css={{ marginBlockEnd: '$one' }}
                                  >
                                    {/* @ts-ignore */}
                                    <Popover.Close asChild>
                                      <Button
                                        icon="tinyClose"
                                        hideLabel
                                        priority="tertiary"
                                        quiet
                                        size="toolbar"
                                        css={{ padding: 0 }}
                                      >
                                        {formatMessage({ id: 'STR_CLOSE' })}
                                      </Button>
                                    </Popover.Close>
                                  </StackLayout>
                                }
                                showCloseButton={false}
                              >
                                <UtilityText
                                  as="fieldset"
                                  emphasis="emphasized"
                                  css={{
                                    paddingBlockEnd: '$half',
                                    display: 'block',
                                  }}
                                >
                                  [{preferenceName}]
                                </UtilityText>
                                <ParagraphText size="sm">
                                  {preferenceDescription}
                                </ParagraphText>
                              </Popover.Content>
                            </Popover.Root>
                          </StackLayout>
                          <StackLayout
                            orientation="horizontal"
                            horizontalAlignment="end"
                            spacing="$half"
                          >
                            {availableMethods?.map((method) => {
                              const { communicationMethodCode } = method;
                              const formFieldName = normalizeFieldName(
                                sectionName,
                                preferenceName,
                              );

                              if (!communicationMethodCode) return null;

                              return (
                                <ChoiceButton
                                  {...register(`${formFieldName}`)}
                                  onClick={() =>
                                    handleChoiceButtonClick({
                                      preferenceName,
                                      communicationMethodCode,
                                    })
                                  }
                                  key={`${formFieldName}_${communicationMethodCode}`}
                                  inputType="radio"
                                  label={
                                    choiceButtonLabels[communicationMethodCode]
                                  }
                                  value={communicationMethodCode}
                                  id={`${formFieldName}_${communicationMethodCode}`}
                                  css={{
                                    flexGrow: isBelowTablet ? '1' : undefined,
                                  }}
                                />
                              );
                            })}
                          </StackLayout>
                        </StackLayout>
                        {!isLastPreference && (
                          <Divider css={{ marginBlock: '$one' }} />
                        )}
                      </Box>
                    );
                  })}
                </ActionsList>
              );
            })}
          </FullPageContentSection>

          {userHasTouchedField && (
            <CTAFooter
              showPrimaryBtn
              primaryBtnLabel={formatMessage({ id: 'STR_SAVE_CHANGES' })}
              primaryBtnProps={{ type: 'submit', loading: mutationIsLoading }}
              data-state={formState.isDirty ? 'shown' : 'hidden'}
              contentWidth={`calc(${CONTENT_MAX_WIDTH} - $twoAndHalf)`}
              showSecondaryBtn
              secondaryBtnLabel={formatMessage({ id: 'STR_CANCEL_EDIT' })}
              secondaryBtnProps={{
                onClick: () =>
                  history.push(ROUTE_PATHS.COMMUNICATION_PREFERENCES),
              }}
              css={{
                transform: formState.isDirty
                  ? 'translateY(0%)'
                  : 'translateY(100%)',
                '&[data-state="shown"]': {
                  animation: `${translateInBottom} $transitions$defaultTime ease-in-out`,
                  '@media (prefers-reduced-motion)': { animation: 'none' },
                },
                '&[data-state="hidden"]': {
                  animation: `${translateOutBottom} $transitions$defaultTime ease-out`,
                  '@media (prefers-reduced-motion)': { animation: 'none' },
                },
                paddingBlockEnd: isBelowTablet ? '$five' : '$oneAndHalf',
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
