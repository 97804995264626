export const API_PATHS = {
  COMMUNICATION_PREFERENCES: '/v1/communication-preferences',
  SELECT_OPTIONS: '/v1/menu',
  MEMBER_PROVIDERS: '/v1/member-providers',
  USERS: '/v1/users',
  IDENTITY_PARTNER_TOKEN: '/v1/identity-partner-token',
};

export const QUERY_KEYS = {
  COMMUNICATION_PREFERENCES: 'COMMUNICATION-PREFERENCES',
  SELECT_OPTIONS: 'SELECT_OPTIONS',
};

export const MESSAGE_TYPES = {
  LINK_EMAIL: 'link_email',
  SET_POST_FTUX: 'set_post_ftux',
};

export const MUTATION_KEYS = {
  COMMUNICATION_PREFERENCES: {
    PATCH: 'PATCH-COMMUNICATION-PREFERENCES',
  },
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  MEMBER_PROVIDERS: {
    PATCH: 'PATCH-MEMBER_PROVIDERS',
  },
  UPDATE_USER_EMAIL: 'UPDATE_USER_EMAIL',
  SET_POST_FTUX: 'SET_POST_FTUX',
};
