import { StackLayout } from '@leagueplatform/genesis-core';
import { ActivityStackWidgetV2 } from '@leagueplatform/health-journey';
import { useRouteMatch } from 'react-router-dom';
import { ROUTE_PATHS } from 'common/constants/constants';
import { CONTENT_MAX_WIDTH } from 'common/constants/ui';
import { PageHeader } from 'components/page-header/page-header.component';
import { ComponentProps } from 'react';

export const ActivityStack = (
  props: ComponentProps<typeof ActivityStackWidgetV2>,
) => {
  const isGenericMasonryPage = useRouteMatch({
    path: [`${ROUTE_PATHS.GENERIC_MASONRY_PAGE}/:version/:id`],
    exact: true,
  });

  // Use the default Activities Stack Widget on all pages that aren't a Generic Masonry Page
  if (!isGenericMasonryPage) return <ActivityStackWidgetV2 {...props} />;

  const { heading, items, traits } = props;

  return (
    <>
      <PageHeader text={heading} historyGoBack />
      <StackLayout
        css={{
          maxWidth: `calc(${CONTENT_MAX_WIDTH}px + $two)`,
          width: '100%',
          marginInline: 'auto',
          marginBlockStart: '$three',
          paddingInline: '$two',
        }}
      >
        <ActivityStackWidgetV2 items={items} traits={traits} />
      </StackLayout>
    </>
  );
};
