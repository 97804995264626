import { useMemo } from 'react';
import { useIntl, getCurrentLocale } from '@leagueplatform/locales';
import { EXTERNAL_URLS } from 'common/constants/constants';
import type {
  AppLocales,
  AppStoreLinkProps,
  FooterLink,
  LocalesKey,
} from 'common/types/types';
import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';
import { getAddresses } from 'common/utils/addresses';
import APP_STORE_ZH from '../../assets/app-store/app-store-zh.png';
import APP_STORE_EN from '../../assets/app-store/app-store-en.png';
import APP_STORE_ES from '../../assets/app-store/app-store-es.png';
import APP_STORE_KO from '../../assets/app-store/app-store-ko.png';
import APP_STORE_VI from '../../assets/app-store/app-store-vi.png';
import GOOGLE_STORE_EN from '../../assets/google-play-store/google-store-en.png';
import GOOGLE_STORE_ZH from '../../assets/google-play-store/google-store-zh.png';
import GOOGLE_STORE_ES from '../../assets/google-play-store/google-store-es.png';
import GOOGLE_STORE_KO from '../../assets/google-play-store/google-store-ko.png';
import GOOGLE_STORE_VI from '../../assets/google-play-store/google-store-vi.png';

interface StoreImages {
  googlePlayStoreImage: string;
  appStoreImage: string;
}

const localeStoreImages: Record<AppLocales, StoreImages> = {
  en: {
    googlePlayStoreImage: GOOGLE_STORE_EN,
    appStoreImage: APP_STORE_EN,
  },
  zh: {
    googlePlayStoreImage: GOOGLE_STORE_ZH,
    appStoreImage: APP_STORE_ZH,
  },
  es: {
    googlePlayStoreImage: GOOGLE_STORE_ES,
    appStoreImage: APP_STORE_ES,
  },
  ko: {
    googlePlayStoreImage: GOOGLE_STORE_KO,
    appStoreImage: APP_STORE_KO,
  },
  vi: {
    googlePlayStoreImage: GOOGLE_STORE_VI,
    appStoreImage: APP_STORE_VI,
  },
};

export const getBaseLocale = <T extends Record<AppLocales, StoreImages>>(
  localeObject: T,
): AppLocales => {
  const currentLocale = getCurrentLocale();
  const localeLanguage = currentLocale.split('-')[0] as AppLocales;

  return Object.hasOwn(localeObject, localeLanguage) ? localeLanguage : 'en';
};

const getFooterDescriptionId = (state: string): LocalesKey => {
  switch (state) {
    case 'CA':
      return 'STR_FOOTER_DESCRIPTION_CA';
    case 'AZ':
      return 'STR_FOOTER_DESCRIPTION_AZ';
    case 'NM':
      return 'STR_FOOTER_DESCRIPTION_NM';
    default: // covers TX and NV since content for both states are the same.
      return 'STR_FOOTER_DESCRIPTION';
  }
};

const getPlanName = (state: string): string => {
  switch (state) {
    case 'AZ':
      return 'SCAN Desert Health Plan';
    case 'NM':
      return 'SCAN Health Plan New Mexico';
    default: // Same name for TX, NV, CA
      return 'SCAN Health Plan';
  }
};

export function useFooterConfig() {
  const { formatMessage } = useIntl();

  // Footer conent is dynamic based on the user's residential state.
  // Supported states include: CA, AZ, TX, NV, and NM.
  const { data: userProfileData } = useGetScanUserProfile();
  const {
    oldResidentialAddress: { state: userResidentialState },
  } = useMemo(
    () => getAddresses(userProfileData?.userProfile?.locations),
    [userProfileData],
  );

  const baseLocale = getBaseLocale(localeStoreImages);

  const { googlePlayStoreImage, appStoreImage } = localeStoreImages[baseLocale];

  const footerLinks: FooterLink[] = [
    {
      to: EXTERNAL_URLS.NON_DISCRIMINATION_POLICY,
      target: '_blank',
      text: formatMessage({ id: 'STR_NON_DISCRIMINATION_POLICY' }),
    },
    {
      to: EXTERNAL_URLS.PRIVACY_POLICY(userResidentialState),
      target: '_blank',
      text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
    },
    {
      to: EXTERNAL_URLS.TERMS_OF_USE(userResidentialState),
      target: '_blank',
      text: formatMessage({ id: 'STR_TERMS_OF_USE' }),
    },
  ];

  const appLinks: AppStoreLinkProps[] = [
    {
      href: '#',
      alt: formatMessage({ id: 'STR_DOWNLOAD_APP_STORE' }),
      src: appStoreImage,
    },
    {
      href: '#',
      alt: formatMessage({ id: 'STR_GET_GOOGLE_PLAY' }),
      src: googlePlayStoreImage,
    },
  ];

  return {
    footerLinks,
    appLinks,
    footerDescriptionId: getFooterDescriptionId(userResidentialState),
    planName: getPlanName(userResidentialState),
  };
}
