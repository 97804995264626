import {
  Button,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { PAGE_NAVIGATION_WIDTH } from 'common/constants/ui';
import { EXTERNAL_URLS } from 'common/constants/constants';
import { ExternalLink } from 'components/external-link/external-link.component';
import { PageHeader } from 'components/page-header/page-header.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

export const ExerciseYourBrain = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN' }));
  const isBelowTablet = useIsBelowTablet();

  return (
    <>
      <PageHeader
        historyGoBack
        text={formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN' })}
        maxWidth={PAGE_NAVIGATION_WIDTH}
      />

      <StackLayout
        orientation={isBelowTablet ? 'vertical' : 'horizontal'}
        spacing={isBelowTablet ? '$two' : '$oneAndHalf'}
        css={{
          maxWidth: PAGE_NAVIGATION_WIDTH,
          marginInline: 'auto',
          paddingBlock: '$two',
          paddingInline: '$oneAndHalf',
        }}
      >
        <ParagraphText css={{ flexShrink: 1 }}>
          {formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN_DESCRIPTION' })}
        </ParagraphText>

        <Button
          as={ExternalLink}
          indicator="externalLink"
          href={EXTERNAL_URLS.SCAN_EXERCISE_YOUR_BRAIN_CTA}
          width={isBelowTablet ? 'fillContainer' : 'hugContents'}
          css={{ flexShrink: 0 }}
        >
          {formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN_CTA' })}
        </Button>
      </StackLayout>
    </>
  );
};
