import {
  StackLayout,
  Box,
  ImageMessage,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import THUMBS_UP from 'assets/thumbs-up-illustration.png';
import { APP_NAME } from 'common/constants/constants';

export const VerifyEmail = () => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  return (
    <StackLayout
      css={{
        width: '100vw',
        height: '100vh',
        backgroundColor: isBelowTablet
          ? '$surfaceCardPrimary'
          : '$surfaceBackgroundPrimary',
      }}
      verticalAlignment="center"
      horizontalAlignment="center"
    >
      <Box
        css={{
          maxWidth: 780,
          backgroundColor: '$surfaceCardPrimary',
          padding: '$twoAndHalf',
          boxShadow: isBelowTablet ? 'none' : '$card',
        }}
      >
        <ImageMessage image={THUMBS_UP} imageWidth={176} imageGap="$twoAndHalf">
          <StackLayout spacing="$threeQuarters" horizontalAlignment="center">
            <HeadingText level="1" size="lg">
              {formatMessage({ id: 'STR_VERIFY_EMAIL_HEADING' })}
            </HeadingText>
            <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
              {formatMessage(
                { id: 'STR_VERIFY_EMAIL_BODY' },
                { appName: APP_NAME },
              )}
            </ParagraphText>
          </StackLayout>
        </ImageMessage>
      </Box>
    </StackLayout>
  );
};
