import { styled, type GDSStyleObject } from '@leagueplatform/genesis-core';
import LOGO from 'assets/images/scan-logo.png';
import { APP_NAME } from 'common/constants/constants';

type LogoProps = {
  width: GDSStyleObject['width'];
  css?: GDSStyleObject;
};

const StyledImage = styled('img');

export const Logo = ({ width, css }: LogoProps) => (
  <StyledImage
    role="presentation"
    alt={APP_NAME}
    src={LOGO}
    css={{
      width,
      ...css,
    }}
  />
);
