/* eslint-disable import/no-extraneous-dependencies */
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants/constants';
import { Box } from '@leagueplatform/genesis-core';
import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  AboutMeRoutes,
  HealthProfileRoutes,
  Home,
  WalletRoutes,
  AccountInfo,
  HealthJourneyRoutes,
  MessagingRoutes,
  CareDocumentsRoutes,
  PriorAuthorizations,
  PriorAuthorizationsDetails,
  Prescriptions,
  PrescriptionDetails,
  RefillScore,
  RefillScoreDetails,
  ContactScan,
} from 'components/lazy-routes.component';
import { AuthViewLogin, AuthViewLogout } from 'pages/auth/auth.pages';
import { VerifyEmail } from 'pages/verify-email.page';
import { AuthorizedRoutes } from 'components/authorized-routes/authorized-routes.component';
import { PrivateRoutesContainer } from 'components/private-routes-container/private-routes-container';
import { GenericSkeletonPage } from 'components/skeleton-page/skeleton-page.component';
import { CommunicationPreferencesPage } from 'pages/communication-preferences/communication-preferences.page';
import { DocumentPreferencesPage } from 'pages/document-preferences/document-preferences.page';
import { AboutPage } from 'pages/about/about.page';
import { pageNotFoundProps } from 'common/constants/error-page';
import { GenericErrorPage } from 'components/error-page/error-page.component';
import { BasePathRedirect } from 'components/base-path-redirect/base-path-redirect.component';
import { ExerciseYourBrain } from 'pages/exercise-your-brain/exercise-your-brain.page';
import { GenericMasonryPage } from '@leagueplatform/masonry';
import { GenericMasonryPageWrapper } from './generic-masonry-page-wrapper/generic-masonry-page-wrapper.component';

// CareDiscovery overflows our grid layout parent at the bottom in mobile width.
// It's made of StackLayout, which has height: 100%.
// It's built in Masonry 1.0, so we can't override Masonry's StackLayout (we could in 1.5).
// It doesn't have any distinct class names to target the div directly.
// The grandchildren of this Box are the 2 container columns.
// Adding more elements inside the columns will NOT disrupt the grandchildren selector.
const StyledCareDiscoveryRoutes = () => (
  <Box
    css={{
      '.cc-page-header-background': { height: 'max-content' },
      // equal width carousel items
      'ul > li:has(> .GDS-stack-item)': {
        flexGrow: 1,
        flexBasis: 0,
      },
    }}
  >
    <CareDiscoveryRoutes />
  </Box>
);

const StyledWalletRoutes = () => {
  const WALLET_PAGE_HEADER_HEIGHT = `167px`;

  return (
    <Box
      css={{
        '.cc-page-header-background': {
          height: WALLET_PAGE_HEADER_HEIGHT,
          '&:before': {
            top: `calc(${WALLET_PAGE_HEADER_HEIGHT} - 20024px)`,
          },
        },
      }}
    >
      <WalletRoutes />
    </Box>
  );
};

function AppRoutes() {
  return (
    <Switch>
      <Route path={ROUTE_PATHS.VERIFY_EMAIL} exact component={VerifyEmail} />
      <Route path={ROUTE_PATHS.SIGN_IN} component={AuthViewLogin} />
      <Route path={ROUTE_PATHS.SIGN_OUT} component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthorizedRoutes>
        <Switch>
          {/* Authorized view without top nav and global footer */}
          {/* <Route
            path={ROUTE_PATHS.CHANGE_DOCTOR}
            render={() => <ChangeDoctor />}
            exact
          /> */}
          {/* Authorized views with top nav and global footer */}
          <PrivateRoutesContainer>
            <Switch>
              {/* Remove all /member paths - they're hardcoded in some SDKs but the app doesn't handle them anymore. */}
              <Route path={ROUTE_PATHS.MEMBER_REDIRECT}>
                <BasePathRedirect targetBasePath="" />
              </Route>
              <Route
                path={ROUTE_PATHS.REFILL_SCORE_DETAILS}
                component={RefillScoreDetails}
                exact
                key="refill-score-details"
              />
              <Route
                path={ROUTE_PATHS.REFILL_SCORE}
                component={RefillScore}
                exact
                key="refill-score"
              />
              <Route
                path={ROUTE_PATHS.PRESCRIPTIONS}
                component={Prescriptions}
                exact
                key="prescriptions"
              />
              <Route
                path={ROUTE_PATHS.PRESCRIPTION_DETAILS}
                component={PrescriptionDetails}
                exact
                key="prescription-details"
              />
              <Route
                path={ROUTE_PATHS.PRIOR_AUTHORIZATIONS}
                component={PriorAuthorizations}
                exact
                key="prior-authorizations"
              />
              <Route
                path={ROUTE_PATHS.PRIOR_AUTHORIZATIONS_DETAILS()}
                component={PriorAuthorizationsDetails}
                exact
                key="prior-authorizations-details"
              />
              <Route
                path={ROUTE_PATHS.CONTACT_SCAN}
                component={ContactScan}
                exact
                key="contact-scan"
              />
              <Route
                path={ROUTE_PATHS.HOME}
                component={Home}
                exact
                key="home"
              />
              <Route
                path={ROUTE_PATHS.ACCOUNT_INFO}
                render={() => (
                  <Suspense fallback={<GenericSkeletonPage />}>
                    <AccountInfo />
                  </Suspense>
                )}
                exact
              />
              <Route
                path={ROUTE_PATHS.COMMUNICATION_PREFERENCES}
                component={CommunicationPreferencesPage}
                exact
              />
              <Route
                path={ROUTE_PATHS.EXERCISE_YOUR_BRAIN}
                component={ExerciseYourBrain}
                exact
              />
              <Route path={ROUTE_PATHS.ABOUT} component={AboutPage} exact />
              <Route
                path={ROUTE_PATHS.DOCUMENT_PREFERENCES()}
                render={() => (
                  <Suspense fallback={<GenericSkeletonPage />}>
                    <DocumentPreferencesPage />
                  </Suspense>
                )}
                exact
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                component={CareCollectionsRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                component={StyledCareDiscoveryRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                component={StyledWalletRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                component={AboutMeRoutes}
              />
              {/* About Me in Masonry v.1 has metrics hardcoded to /health-profile.
              The metrics are now under /about-me. */}
              <Route
                path={`${getModulePath(
                  LEAGUE_MODULE_NAMES.healthProfile,
                )}/insights`}
              >
                <BasePathRedirect
                  targetBasePath={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                />
              </Route>
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                component={HealthProfileRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
                component={MessagingRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.careDocuments)}
                component={CareDocumentsRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.healthJourney)}
                component={HealthJourneyRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.healthPrograms)}
                component={HealthJourneyRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.healthProgress)}
                component={HealthJourneyRoutes}
              />
              <CompatRoute
                path={getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory)}
                component={HealthJourneyRoutes}
              />
              <CompatRoute
                path={`${ROUTE_PATHS.GENERIC_MASONRY_PAGE}/:version/:id`}
                render={() => (
                  <GenericMasonryPage
                    pageWrapperComponent={GenericMasonryPageWrapper}
                  />
                )}
                exact
              />
              {/* <Route path="/pbp-change" component={PBPChange} /> */}
              <Route
                render={() => <GenericErrorPage {...pageNotFoundProps} />}
              />
            </Switch>
          </PrivateRoutesContainer>
        </Switch>
      </AuthorizedRoutes>
    </Switch>
  );
}

export default AppRoutes;
