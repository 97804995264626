import {
  StackLayout,
  Box,
  ParagraphText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { APP_NAME_LONG, EXTERNAL_URLS } from 'common/constants/constants';
import { ExternalLink } from 'components/external-link/external-link.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { useFooterConfig } from 'hooks/use-footer-config/use-footer-config.hook';
import { PAGE_NAVIGATION_WIDTH } from 'common/constants/ui';

/* [SCAN-3081] Hide App links for soft launch
const AppStoreIconImage = styled('img', {
  width: '158px',
});

const AppStoreLink = ({ href, alt, src }: AppStoreLinkProps) => (
  <a href={href}>
    <AppStoreIconImage alt={alt} src={src} />
  </a>
);
*/

export const GlobalFooter = () => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  const { footerLinks, footerDescriptionId, planName } = useFooterConfig();

  return (
    <StackLayout
      horizontalAlignment="center"
      css={{
        backgroundColor: '$interactiveActionPrimary',
        paddingInline: '$three',
        paddingBlock: isBelowTablet ? '$oneAndHalf' : '$three',
        '&& *': {
          color: '$onSurfaceTextReversed',
          '&:focus': {
            borderRadius: '$circle',
            outline: '$borderWidths$thin dotted $colors$onSurfaceTextReversed',
            outlineOffset: '$borderWidths$outerFocus',
          },
        },
      }}
    >
      <StackLayout
        orientation={isBelowTablet ? 'vertical' : 'horizontal'}
        horizontalAlignment="center"
        css={{
          maxWidth: PAGE_NAVIGATION_WIDTH,
        }}
      >
        {/* [SCAN-3081] Hide App links for soft launch */}
        {/* <StackItem
          css={{
            gap: '$threeQuarters',
            marginInlineEnd: isBelowTablet ? '$none' : '$twoAndHalf',
            marginBlockEnd: isBelowTablet ? '$two' : '$none',
          }}
        >
          
          {appLinks.map((appLink) => (
            <AppStoreLink key={appLink.alt} {...appLink} />
          ))}
        </StackItem> */}

        <StackLayout spacing={isBelowTablet ? '$two' : '$oneAndHalf'}>
          <StackLayout
            orientation={isBelowTablet ? 'vertical' : 'horizontal'}
            spacing={isBelowTablet ? '$half' : '$two'}
          >
            {footerLinks.map((footerLink) => (
              <Box
                as="a"
                href={footerLink.to}
                target={footerLink.target}
                rel="noopener noreferrer"
                key={footerLink.text}
                css={{
                  typography: '$subtitleTwo',
                }}
              >
                {footerLink.text}
              </Box>
            ))}
          </StackLayout>

          <Box>
            <ParagraphText
              css={{
                typography: '$caption',
              }}
            >
              {formatMessage(
                {
                  id: footerDescriptionId,
                },
                {
                  fullDisclaimerLink: (
                    <ExternalLink href={EXTERNAL_URLS.DISCLAIMER}>
                      {formatMessage({ id: 'STR_CLICK_READ_FULL_DISCLAIMER' })}
                    </ExternalLink>
                  ),
                  planName,
                },
              )}
            </ParagraphText>
          </Box>

          <UtilityText
            css={{
              typography: '$label',
            }}
          >
            {formatMessage(
              { id: 'STR_SCAN_HEALTH_COPYRIGHT' },
              { appName: APP_NAME_LONG },
            )}
          </UtilityText>
        </StackLayout>
      </StackLayout>
    </StackLayout>
  );
};
