import { useMutation } from 'react-query';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { useErrorBoundary } from 'react-error-boundary';
import { API_PATHS, MUTATION_KEYS } from 'common/constants/api';
import { getUserProfile } from '@leagueplatform/user-profile-api';

export const useDeleteAccountMutation = () => {
  const { showBoundary } = useErrorBoundary();

  return useMutation<unknown, unknown, {}, unknown>({
    mutationKey: [MUTATION_KEYS.DELETE_ACCOUNT],
    mutationFn: async () => {
      try {
        const userProfileResponse = await getUserProfile();

        if (!userProfileResponse?.user_id) {
          throw new Error(`unable to get user_id.`);
        }

        const { user_id: userId } = userProfileResponse;

        const response = await leagueFetch(`${API_PATHS.USERS}/${userId}`, {
          method: 'DELETE',
        });

        if (!response.ok) {
          throw new Error(`${response.status}`);
        }

        return true;
      } catch (e) {
        showBoundary(e);
        return false;
      }
    },
  });
};
