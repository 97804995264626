import { Auth } from '@leagueplatform/auth';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { captureAppMessage } from '@leagueplatform/observability';
import { getIdentityPartnerIdToken } from 'common/api/get-identity-partner-token';
import { SENTRY_CONSTANTS, POST_LOGOUT_URL } from 'common/constants/constants';
/**
 * Logs the user out using federated authentication.
 * @param returnUrl The URL to redirect to after logout.
 */
export async function logout(): Promise<void> {
  try {
    const partnerToken = await getIdentityPartnerIdToken();

    if (!partnerToken) {
      captureAppMessage('Logout Warning - Missing partner token', {
        context: {
          contextDetails: {
            info: 'No valid federated logout URL returned from the backend.',
          },
        },
        ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
        severityLevel: 'warning',
      });
      // Redirect to root if logout failed
      window.location.replace('/');
    }

    const token = await Auth.getToken();

    const logoutOptions = {
      federated: true,
      accessToken: token,
      returnTo: `${POST_LOGOUT_URL}?idToken=${partnerToken}`,
    };

    StandaloneAuth.client.logout(logoutOptions);
  } catch (error: any) {
    captureAppMessage(`Logout Error - ${error.name}`, {
      context: {
        contextDetails: {
          info: `An exception occurred during logout. Error: ${error.message}`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      severityLevel: 'error',
    });
    // Redirect to root if logout failed
    window.location.replace('/');
  }
}
