import type { DropdownSection } from '@leagueplatform/web-common-components';
import {
  ROUTE_PATHS,
  EXTERNAL_URLS,
  APP_NAME_LONG,
  APP_NAME,
} from 'common/constants/constants';
import type { Link } from 'common/types/types';
import { useIntl } from '@leagueplatform/locales';
import ExternalLinkIcon from '../../assets/external-link-icon.png';

export type AppDropdownSection = (Omit<DropdownSection[number], 'text'> &
  Link)[];

type UserDropdownItems = {
  heading?: string;
  items: Link[];
};

export const useUserDropdownConfig = (
  handleDeleteAccount: () => void,
): UserDropdownItems[] => {
  const { formatMessage } = useIntl();

  return [
    {
      // Account
      heading: formatMessage({ id: 'STR_ACCOUNT' }),
      items: [
        {
          // Account Information
          text: formatMessage({ id: 'STR_ACCOUNT_INFO' }),
          to: ROUTE_PATHS.ACCOUNT_INFO,
        },
        {
          // Communication Preferences
          text: formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' }),
          to: ROUTE_PATHS.COMMUNICATION_PREFERENCES,
        },
      ],
    },
    {
      heading: formatMessage({ id: 'STR_FORMS' }),
      items: [
        {
          text: formatMessage({ id: 'STR_AUTO_PAY_REQUEST' }),
          to: EXTERNAL_URLS.AUTO_PAY_REQUEST,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_DISENROLLMENT_FORM' }),
          to: EXTERNAL_URLS.DISENROLLMENT_FORM,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_PHI_REQUEST_FORM' }),
          to: EXTERNAL_URLS.PHI_REQUEST_FORM,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_GRIEVANCES_AND_APPEALS' }),
          to: EXTERNAL_URLS.GRIEVANCES_AND_APPEALS,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_FIVE_WISHES' }),
          to: EXTERNAL_URLS.FIVE_WISHES,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
      ],
    },
    {
      // About
      heading: formatMessage({ id: 'STR_ABOUT' }),
      items: [
        {
          // About SCAN Health
          text: formatMessage(
            { id: 'STR_ABOUT_SCAN_HEALTH' },
            { appName: APP_NAME_LONG },
          ),
          to: ROUTE_PATHS.ABOUT,
        },

        {
          // Contact SCAN
          text: formatMessage(
            { id: 'STR_CONTACT_SCAN' },
            { appName: APP_NAME },
          ),
          to: ROUTE_PATHS.CONTACT_SCAN,
        },

        {
          // Apps for SCAN benefits
          text: formatMessage(
            { id: 'STR_APPS_FOR_SCAN_BENEFITS' },
            { appName: APP_NAME },
          ),
          to: EXTERNAL_URLS.APPS_FOR_SCAN_BENEFITS,
          target: '_blank',
          icon: ExternalLinkIcon,
        },

        {
          // Privacy Policy
          text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
          to: EXTERNAL_URLS.PRIVACY_POLICY(),
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          // Terms of Service
          text: formatMessage({ id: 'STR_TERMS_OF_SERVICE' }),
          to: EXTERNAL_URLS.TERMS_OF_USE(),
          target: '_blank',
          icon: ExternalLinkIcon,
        },

        {
          // Delete your account
          text: formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' }),
          onClick: handleDeleteAccount,
        },
      ],
    },
    {
      items: [
        {
          to: ROUTE_PATHS.SIGN_OUT,
          text: formatMessage({ id: 'STR_SIGN_OUT' }),
          linkProps: {
            color: 'onSurface.text.critical',
          },
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];
};
