import { DATE_FORMAT } from '@leagueplatform/web-common';

export const UTC_DATE_FORMAT = { ...DATE_FORMAT, timeZone: 'UTC' };

export const APP_ID = 'scan-web';
export const TENANT_ID = 'scan';
export const APP_NAME = 'SCAN';
export const APP_NAME_LONG = 'SCAN Health';

export const ROUTE_PATHS = {
  VERIFY_EMAIL: '/verify-email',
  HOME: '/home',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  ACCOUNT_INFO: '/settings/account-information',
  COMMUNICATION_PREFERENCES: '/communication-preferences',
  ABOUT: '/about',
  DOCUMENT_PREFERENCES: () =>
    `${ROUTE_PATHS.COMMUNICATION_PREFERENCES}/document-preferences`,
  CHANGE_DOCTOR: '/change-doctor',
  MEMBER_REDIRECT: '/member',
  PRIOR_AUTHORIZATIONS: '/get-care/prior-authorizations',
  PRIOR_AUTHORIZATIONS_DETAILS: () => `/prior-authorizations/:authorizationId`,
  PRESCRIPTIONS: '/prescriptions',
  PRESCRIPTION_DETAILS: '/prescriptions/:prescriptionId',
  REFILL_SCORE: '/refill-score',
  REFILL_SCORE_DETAILS: '/refill-score/:category',
  CONTACT_SCAN: '/contact-scan',
  EXERCISE_YOUR_BRAIN: '/exercise-your-brain',
  GENERIC_MASONRY_PAGE: '/masonry',
  BENEFITS: '/benefits',
  HEALTH_ASSESSMENT:
    '/health-journey/campaign/4hjfHxSH6kVJbFbJcqoHOG/activity/EQkTzwL393rgcz2MXvkZl/toolbox',
};

export const LOCAL_STORAGE_KEYS = {
  FEATURE_HIGHLIGHTS_COMPLETED: 'feature_highlights_completed',
};

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scan-web',
};

export const COMMUNICATION_METHOD_CODES = {
  ONLINE: 'email',
  MAIL: 'mail',
  NONE: 'none',
};

export const POST_LOGOUT_URL = 'https://www.scanhealthplan.com';

export const EXTERNAL_URLS = {
  TERMS_OF_USE: (state?: string): string => {
    switch (state) {
      case 'CA':
        return 'https://www.scanhealthplan.com/legal-notices/terms-of-use';
      case 'AZ':
        return 'https://www.scanhealthplan.com/legal-notices/az-terms-of-use';
      case 'NM':
        return 'https://www.scanhealthplan.com/legal-notices/nm-terms-of-use';
      case 'NV':
        return 'https://www.scanhealthplan.com/legal-notices/nv-terms-of-use';
      case 'TX':
        return 'https://www.scanhealthplan.com/legal-notices/tx-terms-of-use';
      default:
        return 'https://www.scanhealthplan.com/legal-notices';
    }
  },
  PRIVACY_POLICY: (state?: string): string => {
    switch (state) {
      // case 'AZ':
      //   return 'https://www.scanhealthplan.com/legal-notices/az-privacy';
      // case 'NM':
      //   return 'https://www.scanhealthplan.com/legal-notices/nm-privacy';
      // case 'NV':
      //   return 'https://www.scanhealthplan.com/legal-notices/nv-privacy';
      // case 'TX':
      //   return 'https://www.scanhealthplan.com/legal-notices/tx-privacy';
      default:
        // covers CA as it is the default
        // return 'https://www.scanhealthplan.com/legal-notices/privacy';
        return 'https://www.scanhealthplan.com/legal-notices/web-and-mobile-privacy-policy';
    }
  },
  NON_DISCRIMINATION_POLICY:
    'https://www.scanhealthplan.com/legal-notices/nondiscrimination-and-accessiblity-requirements',
  DISCLAIMER: 'https://www.scanhealthplan.com/legal-notices',
  SCAN_SCHEDULE_CALL:
    'https://www.scanhealthplan.com/members/public-mindful-widget',
  SCAN_EXERCISE_YOUR_BRAIN_CTA:
    'https://www.scanhealthplan.com/supplemental-benefits-2025/fitness-membership',
  AUTO_PAY_REQUEST:
    'https://www.scanhealthplan.com/members/account/support/request-a-scan-form',
  DISENROLLMENT_FORM:
    'https://www.scanhealthplan.com/-/media/scan/documents/resources/member-forms/disenrollment-form.pdf#zoom=100',
  PHI_REQUEST_FORM:
    'https://www.scanhealthplan.com/-/media/scan/documents/resources/member-forms/phi-request-form.pdf#zoom=100',
  GRIEVANCES_AND_APPEALS:
    'https://www.scanhealthplan.com/members/account/support/appeals-grievances-and-exceptions-report',
  FIVE_WISHES:
    'https://www.scanhealthplan.com/caregivers-and-family/advance-care-planning',
  APPS_FOR_SCAN_BENEFITS: 'https://www.scanhealthplan.com/app-store',
};
