import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
} from 'common/constants/masonry';
import { ActivityStack } from 'components/masonry-renderers/activity_stack.component';

export const initHealthJourneyWSRegistry = () => {
  MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
    MASONRY_NAMESPACES.HEALTH_JOURNEY,
    MASONRY_WIDGET_TYPES.ACTIVITY_STACK,
    ActivityStack,
  );
};
