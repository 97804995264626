import { type PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useAnalyticsIdentify } from '@leagueplatform/web-common';
import { useAuth } from '@leagueplatform/auth-utils';
import { ROUTE_PATHS, TENANT_ID } from 'common/constants/constants';

export const AuthorizedRoutes = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated, error } = useAuth();
  useAnalyticsIdentify({ employer_group_id: '', tenant_id: TENANT_ID });

  // isAuthenticated is initially undefined
  if (error) return <Redirect push to={ROUTE_PATHS.SIGN_OUT} />;
  if (isAuthenticated === undefined) return <LoadingIndicator />;
  if (isAuthenticated === false)
    return <Redirect push to={ROUTE_PATHS.SIGN_IN} />;
  return <>{children}</>;
};
